<template>
  <div>
    <v-card flat elevation="0">
      <v-card-title>List Closed</v-card-title>
      <v-divider></v-divider>
      <v-toolbar dense elevation="0">
        <div style="max-width: 100px">
          <v-select :items="itemsPerPageOption" label="Show Items" dense v-model="datatable_options.itemsPerPage" style="max-width: 100px" hide-details single-line></v-select>
        </div>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="showFilter()" title="Filter">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>

      <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event">
        <template v-slot:[`item.document_no_`]="{ item }" v-if="!isMobile">
          <detail-dialog :id="parseInt(item.id)" @callback="getData(true)">

            <template v-slot:activator="{ on }">
              <a href="javascript:;" v-on:click="on">{{ item.document_no_ }}</a>
            </template>

          </detail-dialog>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>

        <template v-slot:[`item`]="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1">{{ item.document_no_ }}</v-card-title>
            <v-card-subtitle class="my-0 py-0">
              {{ item.document_type }}<br>
              {{ item.area_code }}
            </v-card-subtitle>
            <v-card-actions class="d-flex align-center flex-row mx-2">
              <v-btn @click="redirect('Administration.CPAR.Edit', { id: item.id })" outlined rounded color="primary">Edit</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="redirect('Administration.CPAR.Detail', { id: item.id })" outlined rounded color="primary">Detail</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-data-table>
      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
        <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
      </div>
    </v-card>

    <v-dialog v-model="dialogFilter" max-width="600">
      <v-card>
        <v-toolbar class="text-h5 grey lighten-2" elevation="0">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text icon @click="closeFilter();">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-2" style="min-height: 500px; max-height: 800px;overflow-y: auto;">
          <v-autocomplete v-model="filterForm.status" label="Status" :items="filterData.status" class="mb-2" hide-details multiple deletable-chips chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
          <v-autocomplete v-model="filterForm.substatus" label="Sub Status" :items="filterData.substatus" class="mb-2" hide-details multiple chips deletable-chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
          <v-autocomplete v-model="filterForm.area_code" label="Area" :items="filterData.area_code" item-text="area_code" item-value="area_code" class="mb-2" hide-details multiple chips deletable-chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="runFilter()">Filter</v-btn>
          <v-btn @click="resetFilter()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="closeFilter();">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DetailDialog from "./DetailDialog.vue";
export default {
  components: { DetailDialog },
  data() {
    return {
      loading: null,
      search: "",
      datatable_options: {
        itemsPerPage: 20
      },
      itemsPerPageOption: [20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "No.", value: "document_no_", class: "text-no-wrap" },
          { text: "Document Date", value: "document_date", class: "text-no-wrap" },
          { text: "Source Document", value: "source_document", class: "text-no-wrap" },
          { text: "Document Type", value: "document_type", class: "text-no-wrap" },
          { text: "Area Code", value: "area_code", class: "text-no-wrap" },
          { text: "Department", value: "department.dept_code", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap" },
          { text: "Sub Status", value: "substatus", class: "text-no-wrap" },
          { text: "Created Date", value: "created_date", class: "text-no-wrap" },
          { text: "Created By", value: "created_by_username", class: "text-no-wrap" },
        ],
        data: [],
      },

      dialogFilter: false,
      filterData: {
        status: [
          'open',
          'waiting-approval',
          'approved',
          'cancel',
          'closed',
        ],
        substatus: [
          'on-process',
          'verified',
        ],
        area_code: [],
        department: [],
      },
      filterForm: {
        status: [],
        substatus: [],
        area_code: [],
        department: [],
      }
    };
  },

  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },

  methods: {
    refreshData() {
      this.getData();
    },
    getData(refresh) {
      const uri = "cpar/data-closed";
      if (refresh) this.AxiosStorageRemove("GET", uri);
      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;
      this.datatable.loading = true;
      const params = {
        search: this.search,
        sort_by: sortBy,
        sort_desc: sortDesc,
        page: page,
        limit: itemsPerPage,
        filter: this.filterForm
      }
      this.$axios
        .get(uri, {
          sessionStorage: !refresh,
          params: params
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    showFilter() {
      this.dialogFilter = true;
      this.filterData.area_code = this.$store.state.master.area;
    },
    closeFilter() {
      this.dialogFilter = false;
    },
    runFilter() {
      this.dialogFilter = false;

      this.getData(true);
    },
    resetFilter() {
      this.filterForm = {
        status: [],
        substatus: [],
        area_code: [],
        department: [],
      }
      this.dialogFilter = false;
      this.getData(true);
    }
  },

  mounted() {
    this.getData();
  },
};
</script>
